import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import ROUTES from './routes'
import './App.scss'

const Home = lazy(() => import('./pages/Home'))
const CustomerExperience = lazy(() => import('./pages/CustomerExperience'))

const App = () => (
  <Suspense fallback={<div />}>
    <Routes>
      <Route exact path={ROUTES.HOME} element={<Home />} />
      <Route
        exact
        path={ROUTES.CUSTOMER_EXPERIENCE}
        element={<CustomerExperience />}
      />
      <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
    </Routes>
  </Suspense>
)

export default App
