import axios from 'axios'
import { QueryClient } from 'react-query'

const api = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND ||
    `https://intelligence-${process.env.REACT_APP_ENV}.sense360eng.com/api/v1`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

api.interceptors.request.use((config) => {
  const token = document.cookie
    .split('; ')
    .find((row) => row.startsWith('token='))
    ?.split('=')[1]

  // eslint-disable-next-line no-param-reassign
  if (token) config.headers.Authorization = `Bearer ${token}`

  return config
})

export const QUERY_KEYS = {
  ACCOUNT: '/account',
  SELECT_BRAND: '/select',
  CUSTOMER_EXPERIENCE: '/customer_experience',
  PETCO_CUSTOMER_EXPERIENCE: '/petco_customer_experience',
}

// Define a default query function that will receive the query key
const defaultQueryFn = ({ queryKey }) => {
  const [endpoint, keys] = queryKey // !TODO refactor params in query keys

  return api
    .get(endpoint, { params: keys?.params })
    .then((res) =>
      res?.data?.error ? Promise.reject(res?.data?.error) : res?.data
    )
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false, // after getting error disable auto-retry
      queryFn: defaultQueryFn,
      staleTime: 5 * 60 * 1000,
    },
  },
})

export default api
